//@flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';
import { List, responsive } from 'react-usit-ui';

import { ns, warning } from '../design/colors/index';
import {
  errorMessageButtonFrame,
  underlineButtonLink,
} from '../design/focusFrame';
import type { FormInput } from '../inputs/types';
import { ConnectedLinkToQuestion } from '../routing/QuestionLink';
import collapse from './images/collapse-red.svg';
import expand from './images/expand-red.svg';
import info from './images/info-red.svg';

const style = {
  container: {
    background: `url(${info}) 10px 12px no-repeat`,
    backgroundSize: 18,
    backgroundColor: warning.light,
    border: `solid 2px ${warning.lighter}`,
    paddingRight: '57px',
    alignItems: 'center',
    color: ns.black,
    fontSize: '16px',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  header: {
    margin: '5px 5px 5px 0',
    paddingLeft: 40,
    fontSize: 18,
    maxWidth: 420,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    margin: [5, 0, 20, 28],
    display: 'inline-block',
    fontSize: 18,
  },
  toggleLink: {
    '-webkit-tap-highlight-color': 'transparent',
    background: `url(${expand}) no-repeat center right`,
    backgroundSize: 18,
    border: 'none',
    textAlign: 'right',
    display: 'inline-block',
    paddingLeft: 20,
    paddingRight: 30,
    color: warning.dark,
    cursor: 'pointer',
    padding: 0,
    margin: '7px 25px',
    fontWeight: 'bold',
    fontSize: 18,
    whiteSpace: 'nowrap',
    ...underlineButtonLink,
  },
  questionLink: {
    color: ns.black,
    fontSize: '16px',
    textDecoration: 'underline',
    ...errorMessageButtonFrame,
  },
  list: {
    margin: '10 0 5px 0 !important',
  },
  listElement: {
    marginBottom: '0 !important',
  },
  collapse: {
    background: `url(${collapse}) no-repeat right`,
    backgroundSize: 18,
  },
  [responsive.media.max640]: {
    container: {
      background: `url(${info}) 10px 8px no-repeat`,
      backgroundSize: 17,
      backgroundColor: warning.light,
      paddingRight: 0,
    },
    toggleLink: {
      margin: '0 15px',
    },
  },
};
type Classes = { [$Keys<typeof style>]: string };

const removeLinks = node => {
  if (node.type === 'tag' && node.name === 'a') {
    node.name = 'span';
    node.attribs = {};
  }
};

const questionTitle = text => {
  if (text == null) {
    return <Trans>Uten tittel</Trans>;
  } else {
    return ReactHtmlParser(text, { transform: removeLinks });
  }
};

export const containerId = 'error-message-container';
const contentId = 'error-message-content';

class ValidationErrorMessage_ extends React.Component<
  {
    invalidInputs: FormInput[],
    classes: Classes,
  },
  { expanded: boolean },
> {
  constructor() {
    super();
    this.state = { expanded: false };
  }
  render() {
    const p = this.props;
    const s = this.state;
    return (
      <div className={p.classes.container} id={containerId}>
        <div className={p.classes.header}>
          <Trans>Skjemaet inneholder {p.invalidInputs.length} feil</Trans>
          <button
            className={classNames([
              p.classes.toggleLink,
              s.expanded && p.classes.collapse,
            ])}
            onClick={() =>
              this.setState(state => ({ expanded: !state.expanded }))
            }
            aria-expanded={s.expanded ? 'true' : 'false'}
            aria-controls={contentId}
          >
            {s.expanded ? <Trans>Lukk</Trans> : <Trans>Se mer</Trans>}
          </button>
        </div>
        {s.expanded && (
          <div className={p.classes.body} id={contentId}>
            {p.invalidInputs.length === 1 ? (
              <Trans>Feilen finnes i dette feltet:</Trans>
            ) : (
              <Trans>Feilene finnes i følgende felter:</Trans>
            )}
            <List>
              <ul className={p.classes.list}>
                {p.invalidInputs.map(input => (
                  <li className={p.classes.listElement} key={input.questionId}>
                    <ConnectedLinkToQuestion
                      className={p.classes.questionLink}
                      questionId={input.questionId}
                      transformTitle={questionTitle}
                    />
                  </li>
                ))}
              </ul>
            </List>
          </div>
        )}
      </div>
    );
  }
}

export const ValidationErrorMessage = injectSheet(style)(
  ValidationErrorMessage_,
);
